import React, { useState } from 'react';
import './styles.css';
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  TimePicker,
} from '@pankod/refine-antd';
import { Checkbox } from 'antd';
import 'antd/dist/antd.css';
import { useApiUrl, useGetIdentity, useNavigation } from '@pankod/refine-core';
import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import '../../styles/antd.less';
import { setFiltersMAp } from '../../store/selectedStore/selectedStore';
import { useDispatch } from 'react-redux';
const { TextArea } = Input;

function Light_cathering() {
  const url = useApiUrl();
  const { push, goBack, replace } = useNavigation();
  const dispatchRedux = useDispatch();

  function setcloseOrder(arg0: boolean): void {
    dispatchRedux(setFiltersMAp([]));
    push('/home');
  }
  const { data: user } = useGetIdentity();
  const [form] = Form.useForm();
  const [eventTime, setEventTime] = useState(null);
  const [showResponse, setshowResponse] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleTimeChange = (time, timeString) => {
    setEventTime(timeString);
  };
  const [Loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const [selectedDate, setSelectedDate] = useState<any>(null);

  const handleDateChange = date => {
    setSelectedDate(date);
  };

  const getCurrentDate = () => {
    return moment().startOf('day');
  };

  const getDisabledHours = () => {
    if (selectedDate && selectedDate.isSame(getCurrentDate(), 'day')) {
      return Array.from({ length: moment().hour() }).map((_, index) => index);
    }

    return [];
  };

  const getDisabledMinutes = selectedHour => {
    if (selectedDate && selectedDate.isSame(getCurrentDate(), 'day')) {
      if (selectedHour === moment().hour()) {
        return Array.from({ length: moment().minute() }).map(
          (_, index) => index,
        );
      }
    }

    return [];
  };

  const onFinish = values => {
    form.validateFields().then(async () => {
      setExpanded(true);

      setLoading(true);

      const date = dayjs(values.eventTime).toISOString();

      values = {
        ...values,
        eventTime: eventTime,
      };

      if (user) {
        values = {
          ...values,
          UserName: user.fullname,
          Email_Address: user.email,
          Telephone: user.phone,
        };
      }

      const payload = { order: values };
      const headers = {
        accept: 'application/json',
        'content-type': 'application/json',
        // Authorization: `Bearer ${token}`,
      };
      const result = await axios
        .post(`${url}/light-cathering/light-cathering`, payload, { headers })
        .then(res => res)
        .catch(err => err);
      if (result.status === 200) {
        setLoading(false);
        setshowResponse(true);
      } else {
        setLoading(false);

        setshowResponse(true);
        setShowError(true);
      }
    });
  };

  const formItemStyle = {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
  };
  return (
    <div
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/backCathering.jpg)`,
        height: showResponse || Loading ? '100vh' : '100%',

        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
      className="LightCathering">
      <div className="LightCathering__header">
        <div
          onClick={() => setcloseOrder(true)}
          className="LightCathering__cancel">
          <span
            className="LightCathering__cancelicon"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/images/icons/close_dark.svg)`,
            }}>
            &nbsp;
          </span>
        </div>
      </div>

      <div className="LightCathering__tabss">light catering</div>
      {Loading && (
        <div className="loader_lightCathering">
          <div className="loader_light_cathering">
            <div className="loader_cube loader_cube--color"></div>
            <div className="loader_cube loader_cube--glowing"></div>
          </div>
        </div>
      )}

      {!Loading && (
        <div className="LightCatheringBody">
          <div
            className={`LightCatheringBody_content animated-div ${
              expanded ? '' : ''
            }`}>
            {!showResponse ? (
              <Form
                style={{ color: 'white' }}
                onFinish={onFinish}
                className="custom-form">
                {!user && (
                  <>
                    <Form.Item
                      style={formItemStyle}
                      label=""
                      name="UserName"
                      rules={[{ required: true }]}>
                      <Input placeholder="Nome" style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                      label=""
                      name="Telephone"
                      rules={[{ required: true }]}>
                      <Input placeholder="Telefono" style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                      label=""
                      name="Email_Address"
                      rules={[{ required: true, type: 'email' }]}>
                      <Input placeholder="Email " style={{ width: '100%' }} />
                    </Form.Item>
                  </>
                )}
                <Form.Item
                  label=""
                  name="eventDate"
                  rules={[{ required: true }]}>
                  <DatePicker
                    style={{ width: '100%' }}
                    format="DD-MM-YYYY"
                    onChange={handleDateChange}
                    disabledDate={currentDate =>
                      currentDate && currentDate < getCurrentDate()
                    }
                    placeholder="Data evento"
                  />
                </Form.Item>
                <Form.Item
                  label=""
                  name="eventTime"
                  rules={[{ required: true }]}>
                  <TimePicker
                    format="HH:mm"
                    placeholder="Orario evento"
                    style={{ width: '100%' }}
                    onChange={handleTimeChange}
                    disabledHours={getDisabledHours}
                    disabledMinutes={getDisabledMinutes}
                  />
                </Form.Item>

                <Form.Item
                  label=""
                  name="deliveryAddress"
                  rules={[{ required: true }]}>
                  <Input
                    placeholder="Indirizzo di consegna"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Form.Item
                  label=""
                  name="numberOfPeople"
                  rules={[{ required: true }]}>
                  <InputNumber
                    placeholder="Numero di persone"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <Form.Item
                  label=""
                  name="budgetPerPerson"
                  rules={[{ required: true }]}>
                  <InputNumber
                    placeholder="Budget a persona"
                    style={{ width: '100%' }}
                    addonBefore="€"
                  />
                </Form.Item>
                <Form.Item
                  label="Cosa stai organizzando di bello?"
                  name="planningFor"
                  rules={[{ required: true }]}>
                  <TextArea />
                </Form.Item>
                <Form.Item
                  rules={[{ required: true }]}
                  label="Cosa dovrà assolutamente esserci"
                  name="items">
                  <Checkbox.Group
                    style={{ display: 'flex', flexDirection: 'row' }}>
                    <div
                      style={{
                        display: 'flex',
                        gap: '10px',
                        flexWrap: 'wrap',
                        justifyContent: 'flex-start',
                      }}>
                      <Checkbox style={{ marginLeft: '8px' }} value="Pizza">
                        Pizza
                      </Checkbox>
                      <Checkbox value="Sushi">Sushi</Checkbox>
                      <Checkbox value="Gelato">Gelato</Checkbox>
                      <Checkbox value="Cocktail">Cocktail</Checkbox>
                      <Checkbox value="Vino">Vino</Checkbox>
                      <Checkbox value="Birra">Birra</Checkbox>
                      <Checkbox value="Salato">Salato</Checkbox>
                      <Checkbox value="Dolce">Dolce</Checkbox>
                      <Checkbox value="Torte">Torte</Checkbox>
                    </div>
                  </Checkbox.Group>
                </Form.Item>
                <Form.Item label="Extra" name="Extra">
                  <Checkbox.Group
                    style={{ display: 'flex', flexDirection: 'row' }}>
                    <div
                      style={{
                        display: 'flex',
                        gap: '10px',
                        flexWrap: 'wrap',
                        justifyContent: 'flex-start',
                      }}>
                      <Checkbox style={{ marginLeft: '8px' }} value="posate">
                        Posate
                      </Checkbox>
                      <Checkbox value="Bicchieri">Bicchieri</Checkbox>
                      <Checkbox value="addobbi_amido">Addobbi</Checkbox>
                      <Checkbox value="Apri_bottiglie">Apri bottiglie</Checkbox>
                      <Checkbox value="Ghiaccio">Ghiaccio</Checkbox>
                    </div>
                  </Checkbox.Group>
                </Form.Item>

                <Form.Item>
                  <div className="submit_lightCatering">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="submit_lightCatering_btn">
                      Submit
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            ) : (
              <>
                <p className="light_cathering_success_text">
                  {!showError
                    ? ' Form compilato con successo! Ti ricontatteremo presto!'
                    : 'Qualcosa è andato storto, prova ancora'}
                </p>
                <Button
                  type="primary"
                  onClick={() => {
                    if (!showError) {
                      push('/home');
                    } else {
                      setLoading(false);
                      setShowError(false);
                      setshowResponse(false);
                    }
                  }}>
                  {!showError ? 'Home' : 'Try again'}
                </Button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Light_cathering;
