import { useNavigation, useTranslate } from '@pankod/refine-core';
import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import mapboxgl from 'mapbox-gl';
import { Map as ReactMap, Marker, Popup, Source, Layer } from 'react-map-gl';
import { StoreSubcategoryEnum } from 'types/stores/storeSubcategoryEnum';
import Pin from './utilities/pin';
import LocationPin from './utilities/location-pin';
import RadialMenu from '../radialMenu';
import DefaultMarkerPin from './utilities/default-marker-pin';
import { Button, Modal, notification } from '@pankod/refine-antd';
import { Coords, Address } from 'interfaces';
import UserHandler from 'util/UserHandler';
import axios from 'axios';
import { AddressModal } from '../addressModal';
import { BigIconButton, EBigIconButtonType } from '../bigIconButton';
import Sheet from 'react-modal-sheet';

import icons from 'constants/icons';
import 'mapbox-gl/dist/mapbox-gl.css';
import * as worker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import mapConstants from 'constants/mapConstants';
import images from '../../constants/images';
import { add, debounce } from 'lodash';
import type { LayerProps, MapRef } from 'react-map-gl';

(mapboxgl as any).workerClass = worker.default;
import './styles.css';

import useIsClosed from 'hooks/useIsClosed';
import { urlToHttpOptions } from 'url';
import { current } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, store } from 'store';
import { log } from 'console';
import { getDistance } from 'util/getDistance';
import Selected_address from '../selectedAdd';
import { setfilterlists } from 'store/filteredLists/filteredLists';
import StorePopUp from './StorePopUp/StorePopUp';
import * as turf from '@turf/turf';
import { BottomSheet } from 'react-spring-bottom-sheet';
import BottomPanelMap from '../bottomSheetMap/BottomPanel';

import gsap from 'gsap';
import OutsideAlerter from 'hooks/outsideClick';
import SwipeableViews from 'react-swipeable-views';
import useSwipe from 'hooks/useSwipte';
import { FeatureCollection, Geometry } from 'geojson';
import { TagTooltip } from 'components/tag';
import filterListItems from 'constants/filterListItems';
import {
  change_store,
  remove_current_store,
  setFavAddress,
  setFiltersMAp,
  setInView,
  setRelatedStores,
} from 'store/selectedStore/selectedStore';

export type NotificationType = 'success' | 'info' | 'warning' | 'error';

declare global {
  interface WindowEventMap {
    sora_message: { detail: IAppPayload };
  }
}

interface IMarker {
  img_path: any;
  min_order: any;
  ok_votes: any;
  total_votes: any;
  id: number;
  name: string;
  icon: string;
  latitude: number;
  longitude: number;
  category: string;
  subcategory: string;
  subcategory2: string;
  subcategory3: string;
  isOpened: boolean;
  isClosed: boolean;
  nextOpen?: string;
}

interface IMapAddressFrame {
  readonly?: boolean;
  coords?: Coords;
  showButton?: boolean;
  showDefaultMarkers?: boolean;
  showPointMarker?: boolean;
  home?: boolean;
  getCoordsHandler?: (coords: Coords) => void;
  getAddressHandler?: (address: Address) => void;
  reload?: any;
  navigation?: any;
  rounded?: boolean;
  allOverTheworld?: any;
}

export const MapEventType = 'sora_message';

export interface IAppPayload {
  type: EMapType;
  location?: Coords;
  points?: { point: Coords; marker: string }[];
  search?: string;
  subcategories?: number[];
  functions?: ((data: any, extra?: any) => Promise<boolean>)[];
  results?: any;
  stepByStep?: boolean;
}

export enum EMapType {
  toLocation = 'map-to-location',
  filters = 'map-filter',
  setPoints = 'set-points',
  removePoints = 'remove-points',
  resetPoints = 'reset-points',
  setPath = 'set-path',
  setStepByStep = 'set-step-by-step',
  onClick = 'on-click',
  clearMap = 'clear-map',
}

// Marker icon folder map "./images/icons/<category>/<subcategory>.png"
export const mapImgConsts: any = {
  cibo: ['food', 'drink', 'shopping'],
  spesa: ['shopping', 'grocieries', 'drink'],
  acquisti: ['shopping', 'grocieries', 'drink', 'food'],
  bevande: ['drink'],
  logistica: ['logistics'],
};

// valid subcategories for image
export const subcategories = [
  'asiatico',
  'burgers',
  'gastronomia',
  'mediterraneo',
  'sushi',
  'poke',
  'pizza',
  'carne',
  'dolciegelati',
  'cocktailkit',
  'beer_mug',
  'vino',
  'liquori',
  'cocktail',
  'softdrinks',
  'pescheria',
  'ortofrutta',
  'prodotticaseari',
  'panificati',
  'aromieprofumi',
  'macelleria',
  'salumi',
  'artigianato',
  'bodycare',
  'fiori',
  'petcare',
  'homebeauty',
  'utilities',
  'deposit',
  'event',
  'pill',
  'fountain',
];

interface Point {
  point: Coords;
  marker: string;
}

const mapLayer: any = {
  id: 'route',
  type: 'line',
  source: 'route',
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-color': '#444',
    'line-width': 5,
  },
};

const defaulPath: any = {
  type: 'Feature',
  properties: {},
  geometry: {
    type: 'LineString',
    coordinates: [],
  },
};

export enum EStoreFilters {
  cibo = 'cibo',
  bevande = 'bevande',
  spesa = 'spesa',
  botteghe = 'acquisti',
  consigliati = 'consigliati',
  preferiti = 'preferiti',
}

export const MapBox: React.FC<IMapAddressFrame> = ({
  coords,
  getCoordsHandler,
  getAddressHandler,
  showButton = false,
  showDefaultMarkers = false,
  showPointMarker = false,
  readonly = false,
  home = false,
  reload = 1,
  navigation,
  rounded = false,
  allOverTheworld,
}) => {
  const mapRef = useRef<any>(null);

  const t = useTranslate();
  const { push, replace } = useNavigation();
  const [zoomLevel, setZoomLevel] = useState<number>(12);
  const [point, setPoint] = useState<Coords>(); // variable per selected point on map, presented on map with marker if showPointMarker = true
  const [location, setLocation] = useState<Coords>(); // user current location coordinates
  const [customPoints, setCustomPoints] = useState<Point[]>([]);
  const customPointsRef = useRef<Point[]>(customPoints);
  const [defaulMarkers, setDefaulMarkers] = useState<IMarker[]>([]);
  const [address, setAddress] = useState<any>();
  const [modalInfoVisible, setModalInfoVisible] = useState(false);
  const [stepByStep, setStepByStep] = useState(false);
  const [loaging, setLoaging] = useState(true); // navigation active
  const SelectedStoreReducer = useSelector(
    (state: RootState) => state.SelectedStoreReducer,
  );
  const [popupInfo, setPopupInfo] = useState<{
    type: string;
    marker: IMarker;
  } | null>(null);
  const [storePopup, setStorePopUp] = useState(false);
  const [pathData, setPathData] = useState<any>(defaulPath);
  const [setting, setSetting] = useState<any>();
  const [navToStore, setNavToStore] = useState<IMarker | null>();

  const loagingRef = useRef<boolean>();
  const pathDataRef = useRef<any>({});
  const locationRef = useRef<Coords>();
  const settingRef = useRef<any>();
  const [mapBoxBounds, setMapBoxBounds] = useState<any>();
  const useMapForNavigation = useSelector(
    (state: RootState) => state.useMapfornavigation,
  );

  const Range_filters = useSelector(
    (reduxState: RootState) => reduxState.rangeFilter,
  );
  const [rangeRaduis, setRangeRaduis] = useState(Range_filters.range);
  const rangeRef = useRef<any>();
  useEffect(() => {
    setRangeRaduis(Range_filters.range);
    rangeRef.current = Range_filters.range;
    // fitToBound();
  }, [Range_filters]);

  const fitToBound = () => {
    const center = [position.lng, position.lat];
    const radiusInKilometers = rangeRef.current / 1000;

    const options: any = { steps: 64, units: 'kilometers' };
    const circle = turf.circle(center, radiusInKilometers, options);

    // Calculate the bounding box of the circle
    const bbox = turf.bbox(circle);

    const map = mapRef?.current?.getMap();

    const bounds = [
      [bbox[0], bbox[1]], // Southwest coordinates
      [bbox[2], bbox[3]], // Northeast coordinates
    ];

    map?.fitBounds(bounds, {
      padding: 20,
      duration: 2000,
      bearing: 0,
      pitch: 45,
      essential: true,
    });
  };

  const [viewState, setViewState] = useState({
    longitude: mapConstants.defaultCoords.lng,
    latitude: mapConstants.defaultCoords.lat,
    zoom: 1,
    bearing: 90,
    pitch: 70,

    padding: { top: 0, bottom: 0, left: 0, right: 0 },
  });

  const openNotificationWithIcon = (
    type: NotificationType,
    message?: string,
    desc?: string,
  ) => {
    notification[type]({
      message: message ? message : t('Posizione non valido'),
      description: desc ? desc : t('Il punto selezionato non valido'),
    });
  };

  const checkLimits = (lat: number, lng: number) => {
    if (mapConstants.limits.maxLat < lat || mapConstants.limits.minLat > lat) {
      return false;
    }
    if (mapConstants.limits.maxLng < lng || mapConstants.limits.minLng > lng) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/setting/getCurrent`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${UserHandler.getInstance().token}`,
      },
    })
      .then(resp => resp.json())
      .then(resp => {
        setSetting(resp.data);
      });
    if (coords) {
      setPoint(coords);
    }
  }, []);

  useEffect(() => {
    if (coords) {
      console.log('coords change', coords);

      setPoint(coords);
    }
  }, [coords]);

  useEffect(() => {
    customPointsRef.current = customPoints;
  }, [customPoints]);

  useEffect(() => {
    loagingRef.current = loaging;
  }, [loaging]);

  useEffect(() => {
    pathDataRef.current = pathData;
  }, [pathData]);

  useEffect(() => {
    locationRef.current = location;
  }, [location]);

  useEffect(() => {
    settingRef.current = setting;
  }, [setting]);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const isFirstTimeRef = useRef(isFirstTime);
  useEffect(() => {
    isFirstTimeRef.current = isFirstTime;
  }, [isFirstTime]);
  // Creating handlers for receiving messages from the map and the rest of the app
  useEffect(() => {
    // Handler for commands coming from the app
    const appHandler: (messageData: {
      detail: IAppPayload;
    }) => void = (messageData: { detail: IAppPayload }) => {
      const data = messageData.detail;

      if (data.stepByStep !== undefined) {
        setStepByStep(data.stepByStep);
      } else if (data.type === EMapType.clearMap) {
        setStepByStep(false);
      }
      //if (stepByStep) return;
      switch (data.type) {
        case EMapType.toLocation:
          if (data.location) {
            setLocation(data.location);
          }
          sendCallback({ ...messageData.detail, results: data.location });
          break;
        case EMapType.filters:
          if (isFirstTimeRef.current) {
            setTimeout(() => {
              setIsFirstTime(false);
            }, 3000);

            break;
          } else {
            setFilters(data);
            break;
          }

        case EMapType.setPoints:
          if (data.points?.length) {
            const dPoints = (customPointsRef.current || []).concat(
              data.points || [],
            );
            setCustomPoints([...dPoints]);
            sendCallback({ ...messageData.detail, results: dPoints });
          } else {
            sendCallback({
              ...messageData.detail,
              results: {
                error: true,
                message: 'no points',
              },
            });
          }
          break;
        case EMapType.removePoints:
          if (data.points?.length) {
            const pData = [...customPointsRef.current];

            for (const p of data.points) {
              const index = pData.findIndex(
                d => d.point.lat == p.point.lat && d.point.lng == p.point.lng,
              );
              if (index != -1) {
                pData.splice(index, 1);
              }
            }
            setCustomPoints([...pData]);
            sendCallback({ ...messageData.detail, results: pData });
          } else {
            sendCallback({
              ...messageData.detail,
              results: {
                error: true,
                message: 'no points',
              },
            });
          }
          break;

        case EMapType.resetPoints:
          setCustomPoints([]);
          sendCallback({ ...messageData.detail, results: [] });
          break;
        case EMapType.setPath:
          if (data.points?.length && data.points?.length > 1) {
            for (const p of data.points) {
              if (!checkLimits(p.point.lat, p.point.lng)) {
                openNotificationWithIcon('error');
                sendCallback({
                  ...messageData.detail,
                  results: {
                    error: true,
                    message: 'points not valid',
                  },
                });
                return;
              }
            }
            /**
             * setCustomPoints(data.points);
             * Se le coordinate del punto iniziale o finale non è incluso nella risposta directions fare un altro lazer trateggiata
             * che parte punto iniziale alla prima coordinata della directions e dall l'ultima coordnata di directions al punto finale
             */
            fetch(
              `https://api.mapbox.com/directions/v5/mapbox/walking/${data.points
                .map((d: any) => d.point.lng + ',' + d.point.lat)
                .join(
                  ';',
                )}?alternatives=true&geometries=geojson&language=en&overview=full&steps=true&exclude=ferry&walking_speed=1.32&access_token=${
                process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
              }`,
            )
              .then(res => res.json())
              .then(res => {
                setCustomPoints([
                  {
                    marker: '',
                    point: {
                      lng: res.routes[0].geometry.coordinates[0][0],
                      lat: res.routes[0].geometry.coordinates[0][1],
                    },
                  },
                  {
                    marker: '',
                    point: {
                      lng: res.routes[0].geometry.coordinates[
                        res.routes[0].geometry.coordinates.length - 1
                      ][0],
                      lat: res.routes[0].geometry.coordinates[
                        res.routes[0].geometry.coordinates.length - 1
                      ][1],
                    },
                  },
                ]);
                setPathData({
                  ...pathData,
                  geometry: res.routes[0].geometry,
                });
                sendCallback({
                  ...messageData.detail,
                  results: {
                    distance: res.routes[0].distance,
                    time: res.routes[0].duration,
                  },
                });
              });
          } else {
            notification['error']({
              message: 'Percorso',
              description: 'Selezionare almeno due punti per il percorso!',
            });
          }
          break;
        case EMapType.setStepByStep:
          setStepByStep(data.stepByStep ?? false);
          break;
        case EMapType.clearMap:
          setCustomPoints([]);
          setPathData(defaulPath);
          break;
      }
    };

    // Instantiation and removal of events
    window.addEventListener(MapEventType, appHandler);
    return () => {
      window.removeEventListener(MapEventType, appHandler);
    };
  }, []);

  // callback for commands from app
  const sendCallback = (data: IAppPayload) => {
    const event = new CustomEvent<IAppPayload>(`${MapEventType}-message`, {
      detail: data,
    });
    window.dispatchEvent(event);
  };

  const exeFunctions = async (
    data: any,
    functions?: ((data: any, extra?: any) => Promise<boolean>)[],
  ) => {
    if (functions?.length) {
      for (const func of functions) {
        const extra: any = {
          location: locationRef.current,
          range: rangeRef.current
            ? rangeRef.current
            : settingRef.current.roundtome_range,
        };
        if (await func(data, extra)) {
          return true;
        }
      }
    }
    return false;
  };

  const RaduisFunc = async (data: any, extra: any) => {
    let distance = 0;
    if (extra?.location) {
      distance = getDistance(
        extra.location.lat,
        extra.location.lng,
        data.latitude,
        data.longitude,
      );
    }

    return distance <= (extra.range ?? 300);
  };
  const isClosed = useIsClosed();
  const dispatchRedux = useDispatch();
  const cache = useRef<any>(new Map());

  const debounceStores = (func: any, wait: number) => {
    let timeout: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  };
  const setFilters = debounceStores(async (filters: any, isLoading = true) => {
    const cacheKey = JSON.stringify(filters);
    if (cache.current.has(cacheKey)) {
      setDefaulMarkers(cache.current.get(cacheKey));
      return;
    }

    setDefaulMarkers([]);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API}/food/store?business_name=${
          filters.search || ''
        }`,
      );
      const data = await res.json();

      const storesFiltered: IMarker[] = [];
      const storesFilteredTable: IMarker[] = [];

      await Promise.all(
        data.data.map(async (m: any) => {
          const funcResult = await exeFunctions(m, filters.functions);
          const withinLimits = m.show && checkLimits(m.latitude, m.longitude);
          const matchSubcategories =
            filters.subcategories?.length === 0 ||
            filters.subcategories?.includes(m.category) ||
            filters.subcategories?.includes(m.subcategory) ||
            filters.subcategories?.includes(m.subcategory2) ||
            filters.subcategories?.includes(105) ||
            funcResult;

          if (withinLimits && matchSubcategories) {
            let raduisRes = true;
            if (filters.subcategories?.length) {
              raduisRes = await RaduisFunc(m, {
                location: locationRef.current,
                range: rangeRef.current
                  ? rangeRef.current
                  : settingRef.current.roundtome_range,
              });
            }

            if (raduisRes) {
              if (!filters.subcategories?.includes(105)) {
                const marker = {
                  id: m.id,
                  name: m.business_name,
                  icon: './images/icons/food/pizza.png',
                  longitude: m.longitude,
                  latitude: m.latitude,
                  category: m.category,
                  subcategory: m.subcategory,
                  subcategory2: m.subcategory2,
                  subcategory3: m.subcategory3,
                  isOpened: !isClosed(m),
                  isClosed: isClosed(m),
                  ok_votes: m.ok_votes,
                  min_order: m.min_order,
                  img_path: m.img_path,
                } as IMarker;

                storesFiltered.push(marker);
              }

              if (
                filters.subcategories?.includes(105) &&
                m.block_table_orders
              ) {
                storesFilteredTable.push({
                  id: m.id,
                  name: m.business_name,
                  icon: './images/icons/food/pizza.png',
                  longitude: m.longitude,
                  latitude: m.latitude,
                  category: m.category,
                  subcategory: m.subcategory,
                  subcategory2: m.subcategory2,
                  subcategory3: m.subcategory3,
                  isOpened: !isClosed(m),
                  isClosed: isClosed(m),
                  ok_votes: m.ok_votes,
                  min_order: m.min_order,
                  img_path: m.img_path,
                } as IMarker);
              }
            }
          }
        }),
      );

      const combinedResults = [...storesFiltered, ...storesFilteredTable];
      cache.current.set(cacheKey, combinedResults);
      setDefaulMarkers(combinedResults);
    } catch (error) {
      console.error('Error fetching data', error);
    }
  }, 300);

  const onGetPoint = (event: any) => {
    setPoint(event.lngLat);
    sendCallback({
      type: EMapType.onClick,
      results: event.lngLat,
    });
    if (getCoordsHandler) {
      getCoordsHandler(event.lngLat);
    }
  };

  const getCoords = async () => {
    if (!point) return;
    const lat = point.lat;
    const lng = point.lng;
    console.log(lat, lng);

    if (checkLimits(lat, lng)) {
      const response = await axios
        .get(`${process.env.REACT_APP_API}/map/getAddress`, {
          params: {
            lat: point.lat,
            lng: point.lng,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${UserHandler.getInstance().token}`,
          },
        })
        .then(res => res.data?.data);
      if (response?.coordinates?.lat) {
        setAddress({
          ...response,
          lat: response.coordinates.lat,
          lng: response.coordinates.lng,
          latitude: response.coordinates.lat,
          longitude: response.coordinates.lng,
          address: response.street,
          message: response?.message ?? t('mapFrame.getCoords.addressFound'),
          messageType: 'success',
        });
      } else {
        setAddress({
          message: response?.message ?? t('mapFrame.getCoords.addressNotFound'),
          messageType: 'error',
        });
      }
      setModalInfoVisible(true);
    } else {
      notification['error']({
        message: t('mapFrame.getCoords.areaNotCovered'),
        description: t('mapFrame.getCoords.areaNotCoveredDescription'),
      });
    }
  };

  const handleModalInfoVisibleOk = (values: any) => {
    if (getAddressHandler && values.latitude && values.longitude) {
      getAddressHandler(values);
    }
    setModalInfoVisible(false);
  };

  const getIcon = (data: any) => {
    if (data) {
      const iconsPos: any = icons;
      for (const map of mapImgConsts[data.category]) {
        // const color = map == 'logistics' ? '_b' : ''; make all colours orange

        if (iconsPos[`${map}Orange`][subcategories[data.subcategory - 1]])
          return iconsPos[`${map}Orange`][subcategories[data.subcategory - 1]];
        if (iconsPos[`${map}Orange`][subcategories[data.subcategory2 - 1]])
          return iconsPos[`${map}Orange`][subcategories[data.subcategory2 - 1]];
        if (iconsPos[`${map}Orange`][subcategories[data.subcategory3 - 1]])
          return iconsPos[`${map}Orange`][subcategories[data.subcategory3 - 1]];
      }

      return (
        iconsPos[`${subcategories}Orange`[data.subcategory - 1]] ||
        iconsPos[`${subcategories}Orange`[data.subcategory2 - 1]] ||
        iconsPos[`${subcategories}Orange`[data.subcategory3 - 1]] ||
        ''
      );
    }
  };
  const getIconBlack = (data: any) => {
    if (data) {
      const iconsPos: any = icons;
      for (const map of mapImgConsts[data.category]) {
        const color = map == 'logistics' ? '_b' : '';
        if (iconsPos[map][subcategories[data.subcategory - 1]])
          return iconsPos[map][subcategories[data.subcategory - 1] + color];
        if (iconsPos[map][subcategories[data.subcategory2 - 1]])
          return iconsPos[map][subcategories[data.subcategory2 - 1] + color];
        if (iconsPos[map][subcategories[data.subcategory3 - 1]])
          return iconsPos[map][subcategories[data.subcategory3 - 1] + color];
      }

      return (
        iconsPos[subcategories[data.subcategory - 1]] ||
        iconsPos[subcategories[data.subcategory2 - 1]] ||
        iconsPos[subcategories[data.subcategory3 - 1]] ||
        ''
      );
    }
  };
  const [rendered, setRendered] = useState(false);
  const Drag = (drag: any) => {
    updateTrackingPosition();
    setViewState({
      longitude: drag?.viewState?.longitude,
      latitude: drag?.viewState?.latitude,
      zoom: drag?.viewState?.zoom,
      bearing: 0,
      pitch: 45,

      padding: { top: 0, bottom: 0, left: 0, right: 0 },
    });
  };
  const onZoom = (zoom: any) => {
    updateTrackingPosition();
    setViewState({
      longitude: !useMapForNavigation?.useMapForNavigation
        ? position.lng
        : zoom?.viewState?.longitude,
      latitude: !useMapForNavigation?.useMapForNavigation
        ? position.lat
        : zoom?.viewState?.latitude,
      zoom: zoom?.viewState?.zoom,

      bearing: 0,
      pitch: 45,

      padding: { top: 0, bottom: 0, left: 0, right: 0 },
    });

    setZoomLevel(zoom?.viewState?.zoom ?? 12);
    const markers = [...defaulMarkers];
    setDefaulMarkers([...markers]);
  };
  const [fly, setFly] = useState(false);
  const [position, setPosition] = useState<any>({
    lat: mapConstants.defaultCoords.lat,
    lng: mapConstants.defaultCoords.lng,
  });

  useEffect(() => {
    if (location) {
      setPosition(location);
    }
  }, [location]);

  useEffect(() => {
    setTimeout(() => {
      if (!fly) {
        if (home) {
          mapRef?.current?.flyTo({
            center: [position.lng, position.lat],
            zoom: 18.5,
            bearing: 0,
            pitch: 45,
            essential: true,
            duration: 6000,
          });
        } else {
          mapRef?.current?.flyTo({
            center: coords
              ? [coords.lng, coords.lat]
              : [
                  mapConstants.defaultCoords.lng,
                  mapConstants.defaultCoords.lat,
                ],
            zoom: 12.5,
            bearing: 0,
            pitch: 45,
            essential: true,
            duration: 6000,
          });
        }
      }
      setFly(true);
    }, 4000);
  }, [mapRef.current, position]);

  useEffect(() => {
    if (viewState.latitude !== position.lat && fly && !rounded && home) {
      mapRef?.current?.flyTo({
        center: [position.lng, position.lat],
        zoom: 18.5,
        bearing: 0,
        pitch: 45,
        essential: true,

        duration: 4000,
      });
    }
  }, [position]);

  const HandleGoToStore = (popUpI: { type?: string; marker: any }) => {
    let route = '';
    if (
      popUpI.marker.category === 'logistica' &&
      popUpI.marker.subcategory == StoreSubcategoryEnum.Deposit
    ) {
      route = `/webapp/luggageDelivery?type=luggage&id=${popUpI.marker.id}`;
    } else {
      route = `/webapp/stores/${popUpI.marker.id}`;
    }

    return route;
  };

  const [controlArray, setCOntrolarray] = useState<any>('h');
  const [URL1, setURl1] = useState('https://www.cocaiexpress.com/webapp/home');
  const [URL2, setURl2] = useState('https://www.cocaiexpress.com/webapp');
  useEffect(() => {
    if (process.env.REACT_APP_SERVER === 'https://www.cocaiexpress.com/api') {
      setURl1('https://www.cocaiexpress.com/webapp/home');
      setURl2('https://www.cocaiexpress.com/webapp');
    } else if (
      process.env.REACT_APP_SERVER ===
      'https://vps-7777ca76.vps.ovh.net:3020/api'
    ) {
      setURl1('https://development.cocaiexpress.com/webapp/home');
      setURl2('https://development.cocaiexpress.com/webapp');
    } else if (process.env.REACT_APP_SERVER === 'http://localhost:3020/api') {
      setURl1('http://localhost:3000/webapp/home');
      setURl2('http://localhost:3000/webapp');
    }
  }, [window.location.href]);

  function removeTrailingSlash(url: string) {
    if (url.endsWith('/')) {
      return url.slice(0, -1);
    }
    return url;
  }

  const [northAngle, setNorthAngle] = useState(0);

  useEffect(() => {
    console.log('detecting');

    const handleOrientation = (event: any) => {
      const { alpha } = event;
      // Calculate the north direction

      const north = calculateNorthDirection(alpha);
      setNorthAngle(north);
    };

    window.addEventListener('deviceorientation', handleOrientation, true);

    return () => {
      window.removeEventListener('deviceorientation', handleOrientation, true);
    };
  }, []);

  const calculateNorthDirection = (alpha: any) => {
    return alpha;
  };

  const [addressesData, setaddressesData] = useState<any>();
  const token = UserHandler.getInstance().token;
  const User = UserHandler.getInstance().user;
  const fetchUserAddress = async () => {
    const userId = User.id;
    const accessToken = token;

    if (!userId || !accessToken) {
      return null;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}/v1/user/address/user/${userId}`,
        config,
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching user address:', error);

      return null;
    }
  };

  const fetchData = async () => {
    const addressesD = await fetchUserAddress();
    console.log(addressesD);

    setaddressesData(addressesD);
  };

  useEffect(() => {
    if (User.id && token) {
      fetchData();
    }
  }, [User.id, token]);

  const [favourite, setFavourite] = useState<number>();

  useEffect(() => {
    if (addressesData) {
      addressesData.data.forEach((addre: Address) => {
        if (addre.favourite) return setFavourite(addre.id);
      });
    }
  }, [addressesData]);

  const targetRef = useRef<any>(null);
  const trackingRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // Call updateTrackingPosition initially
    updateTrackingPosition();
  }, []);

  // Update tracking element position based on marker position
  const updateTrackingPosition = () => {
    if (targetRef.current && trackingRef.current) {
      const markerElement = targetRef.current;
      const markerRect = markerElement.getBoundingClientRect();
      const markerPosition = mapRef.current.project(position);
      // Calculate viewport boundaries
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      const trackingWidth = trackingRef.current.offsetWidth;
      const trackingHeight = trackingRef.current.offsetHeight;
      const minX = 0;
      const minY = 0;
      const maxX = viewportWidth - trackingWidth;
      const maxY = viewportHeight - trackingHeight;

      // Constrain marker position within viewport boundaries
      const constrainedX = Math.min(
        Math.max(markerPosition.x - markerRect.width / 2, minX),
        maxX,
      );
      const constrainedY = Math.min(
        Math.max(markerPosition.y - markerRect.height, minY),
        maxY,
      );

      gsap.to(trackingRef.current, {
        x:
          Number(constrainedX) <= 20
            ? Number(constrainedX)
            : Number(constrainedX) - 40,
        y:
          Number(constrainedY) <= 20
            ? Number(constrainedY)
            : Number(constrainedY) - 40,
        duration: 0.5,
        ease: 'power2.inOut',
      });
    }
  };

  const circleLayer: any = {
    id: 'circle-layer',
    type: 'circle',
    paint: {
      'circle-radius': 30,
      'circle-color': 'rgba(255, 165, 0, 0.5)',
      'circle-opacity': 0.8,
    },
  };

  const circleData: FeatureCollection<Geometry> = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [
            SelectedStoreReducer?.inView?.longitude || position.lng,
            SelectedStoreReducer?.inView?.latitude || position.lat,
          ], // Longitude, Latitude of the circle's center
        },
        properties: {},
      },
    ],
  };
  const [circleDataMap, setCircleDataMap] = useState<any>(null);
  const circleLayerMap: LayerProps = {
    id: 'circle-layer-map',
    type: 'line',
    paint: {
      'line-color': 'rgba(255, 165, 0, 0.8)',
      'line-width': 2,
    },
  };
  const circleFillLayer: LayerProps = {
    id: 'circle-fill-layer',
    type: 'fill',
    paint: {
      'fill-color': 'rgba(255, 165, 0, 0.3)',
      'fill-opacity': 0.5,
    },
  };
  useEffect(() => {
    // Get user location

    let latitude: any;
    let longitude: any;

    if (SelectedStoreReducer.inView) {
      latitude = SelectedStoreReducer.inView.latitude;
      longitude = SelectedStoreReducer.inView.longitude;
    } else {
      latitude = position.lat;
      longitude = position.lng;
    }

    setViewState(prev => ({
      ...prev,
      latitude,
      longitude,
    }));

    // Create a circular polygon with a 500m radius
    const center = turf.point([longitude, latitude]);
    const radius = 0.5; // Radius in kilometers (500 meters)
    const options: any = { steps: 64, units: 'kilometers' };
    const circle = turf.circle(center, radius, options);
    const map = mapRef?.current?.getMap();
    // Set the circle data as a polygon
    setCircleDataMap(circle);
    const bbox = turf.bbox(circle);

    map?.fitBounds([
      [bbox[0], bbox[1]],
      [bbox[2], bbox[3]],
      {
        bearing: map?.getBearing(), // Keeps the current bearing fixed
        pitch: map?.getPitch(),
      },
    ]);
    setMapBoxBounds([
      [bbox[0], bbox[1]],
      [bbox[2], bbox[3]],
      {
        bearing: map?.getBearing(), // Keeps the current bearing fixed
        pitch: map?.getPitch(),
      },
    ]);
  }, [useMapForNavigation, SelectedStoreReducer.inView, position]);

  // Function to generate points for a quadratic Bezier curve
  const generateBezierCurve = (
    start: any[],
    control: number[],
    end: any[],
    numPoints = 100,
  ) => {
    const curve = [];
    for (let i = 0; i <= 1; i += 1 / numPoints) {
      const x =
        (1 - i) * (1 - i) * start[0] +
        2 * (1 - i) * i * control[0] +
        i * i * end[0];
      const y =
        (1 - i) * (1 - i) * start[1] +
        2 * (1 - i) * i * control[1] +
        i * i * end[1];
      curve.push([x, y]);
    }
    return curve;
  };

  const start = [
    SelectedStoreReducer?.inView?.longitude,
    SelectedStoreReducer?.inView?.latitude,
  ];
  const end = [position.lng, position.lat];
  const control = [
    (position.lng + SelectedStoreReducer?.inView?.longitude) / 2,
    SelectedStoreReducer?.inView?.latitude < position.lat
      ? position.lat
      : SelectedStoreReducer?.inView?.latitude,
  ]; // Control point for the Bezier curve
  // const onDragRotate = (event: any) => {
  //   const newBearing = event.viewState.bearing;
  //   const newLatitude = event.viewState.latitude;
  //   const newLongitude = event.viewState.longitude;

  //   setViewState(prev => ({
  //     ...prev,
  //     bearing: newBearing,
  //     latitude: newLatitude,
  //     longitude: newLongitude,
  //   }));
  // };
  const coordinates = generateBezierCurve(start, control, end);

  // Define the line data
  const lineData: FeatureCollection<Geometry> = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates,
        },
        properties: {},
      },
    ],
  };

  // Define the line layer
  const lineLayer: any = {
    id: 'line-layer',
    type: 'line',
    paint: {
      'line-color': ' #2d2d2d',
      'line-width': 8,
    },
  };

  const [selAdd, setSelAdd] = useState<any>();

  const [isOpen, SetIsOpen] = useState(false);

  const [isDragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState({
    x: 0,
    y: viewState.pitch,
  });
  const isDraggingRef = useRef(isDragging);
  const [endPosition, setEndPOsion] = useState<any>({
    x: 0,
    y: viewState.pitch,
  });
  const startPositionRef = useRef(startPosition);
  const endPositionRef = useRef(endPosition);

  const prevDeltaYRef = useRef(0);

  useEffect(() => {
    endPositionRef.current = endPosition;
  }, [endPosition]);

  const mapViewportYToRange = (y: number) => {
    const viewportHeight = window.innerHeight;
    return (y / viewportHeight) * 65;
  };

  // useEffect(() => {
  //   // Capture mouse movement over the map canvas
  //   if (!useMapForNavigation?.useMapForNavigation) {
  //     const mapCanvas = mapRef?.current?.getCanvasContainer();

  //     const handleMouseDown = (evt: any) => {
  //       setIsDragging(true);
  //       isDraggingRef.current = true;
  //       if (
  //         startPositionRef.current.x === evt.clientX ||
  //         startPositionRef.current.x === 0
  //       ) {
  //         setStartPosition({ x: evt.clientX, y: evt.clientY });
  //         startPositionRef.current = { x: evt.clientX, y: evt.clientY };
  //       }
  //     };

  //     const handleMouseMove = (evt: any) => {
  //       if (isDraggingRef.current) {
  //         const mouseX = evt.clientX;
  //         const mouseY = evt.clientY;
  //         const deltaX = mouseX - startPositionRef.current.x;

  //         const deltaY = mouseY - startPositionRef.current.y;

  //         const diff = prevDeltaYRef.current - mapViewportYToRange(deltaY);

  //         setEndPOsion({
  //           x: deltaX,
  //           y: deltaY,
  //         });
  //         prevDeltaYRef.current = mapViewportYToRange(deltaY);
  //         let lat = position.lat;
  //         let lng = position.lng;

  //         if (SelectedStoreReducer.inView) {
  //           lat = SelectedStoreReducer.inView.latitude;
  //           lng = SelectedStoreReducer.inView.longitude;
  //         }
  //         mapRef?.current?.flyTo({
  //           center: [lng, lat],
  //           zoom: 16,
  //           essential: true,
  //           bearing: 0,
  //           pitch: 45,
  //           duration: 0,
  //         });
  //       }
  //     };

  //     const handleMouseUp = () => {
  //       if (isDraggingRef.current) {
  //         setIsDragging(false);
  //         isDraggingRef.current = false;
  //       }
  //     };

  //     // Add event listeners to the window object
  //     mapCanvas?.addEventListener('mousedown', handleMouseDown);
  //     mapCanvas?.addEventListener('mousemove', handleMouseMove);
  //     mapCanvas?.addEventListener('mouseup', handleMouseUp);
  //     mapCanvas?.addEventListener('mouseleave', handleMouseUp); // Stop dragging if mouse leaves window

  //     // Cleanup event listeners on component unmount
  //     return () => {
  //       mapCanvas?.removeEventListener('mousedown', handleMouseDown);
  //       mapCanvas?.removeEventListener('mousemove', handleMouseMove);
  //       mapCanvas?.removeEventListener('mouseup', handleMouseUp);
  //       mapCanvas?.removeEventListener('mouseleave', handleMouseUp);
  //     };
  //   }
  // }, [
  //   mapRef?.current,
  //   startPosition,
  //   useMapForNavigation,
  //   SelectedStoreReducer.inView,
  // ]);

  useEffect(() => {
    if (fly) {
      const map = mapRef?.current?.getMap();
      if (useMapForNavigation.useMapForNavigation && !rounded && home) {
        mapRef?.current?.flyTo({
          center: [position.lng, position.lat],
          zoom: 14.5,
          essential: true,
          bearing: 0,
          pitch: 45,
          duration: 2000,
        });
        setEndPOsion({
          x: 0,
          y: 45,
        });
      } else if (useMapForNavigation.zoomOut && !rounded && home) {
        mapRef?.current?.flyTo({
          center: [position.lng, position.lat],
          zoom: 14.5,
          essential: true,
          bearing: 0,
          pitch: 45,
          duration: 2000,
        });
      }

      if (!useMapForNavigation.useMapForNavigation) {
        mapRef?.current?.flyTo({
          center: [position.lng, position.lat],
          zoom: 18.5,
          essential: true,
          duration: 2000,
        });
      }
    }
  }, [useMapForNavigation]);

  const mapElement = (
    <ReactMap
      ref={mapRef}
      onRender={event => {
        event.target.resize();

        if (
          // eslint-disable-next-line no-constant-condition
          viewState.bearing === 0 &&
          viewState.zoom >= 12.4 &&
          removeTrailingSlash(window.location.href) !== URL1 &&
          removeTrailingSlash(window.location.href) !== URL2
        ) {
          setCOntrolarray([...controlArray, 'h']);
          if (controlArray.length > 200) {
            const controlArrayTofill = Array(12).fill('h');

            setCOntrolarray(controlArrayTofill);
          }
          setViewState({ ...viewState, bearing: 0.1 });
          setViewState({ ...viewState, bearing: 0 });
        } else if (
          viewState.bearing === 0 &&
          viewState.zoom >= 12.4 &&
          controlArray.length < 10
        ) {
          if (
            removeTrailingSlash(window.location.href) == URL1 ||
            removeTrailingSlash(window.location.href) == URL2
          ) {
            setCOntrolarray([...controlArray, 'h']);
            setViewState({ ...viewState, bearing: 0.1 });
            setViewState({ ...viewState, bearing: 0 });
          }
        }

        setRendered(true);
      }}
      // onRotate={evt => setViewState(evt.viewState)}
      // onDragStart={evt => setViewState(evt.viewState)}
      onMove={evt => {
        // !useMapForNavigation?.useMapForNavigation
        //   ? console.log('')
        //   :

        setViewState(evt.viewState);
      }}
      // onMoveEnd={onDragRotateEnd}
      dragRotate={true}
      dragPan={true}
      trackResize={true}
      // onDrag={useMapForNavigation?.useMapForNavigation ? Drag : console.log('')}
      projection={'globe'}
      doubleClickZoom={true}
      initialViewState={
        rounded ? { latitude: coords?.lat, longitude: coords?.lng } : viewState
      }
      maxBounds={
        !allOverTheworld
          ? // useMapForNavigation?.useMapForNavigation
            //   ?
            [
              mapConstants.bounds.southwest as [number, number],
              mapConstants.bounds.northeast as [number, number],
            ]
          : // : mapBoxBounds
            undefined
      }
      style={
        rounded
          ? { width: '100%', height: '100%', borderRadius: '10px' }
          : { width: '100%', height: '100%' }
      }
      mapStyle="mapbox://styles/eroegrosso/clhqfosgv01v901pn0anr2hfp"
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      onClick={
        rounded
          ? () => {
              console.log('clicked');
            }
          : onGetPoint
      }
      zoom={rounded && !allOverTheworld ? 12.5 : viewState.zoom}
      bearing={endPosition.x}
      pitch={endPosition.y}
      onZoom={onZoom}
      cursor={'pointer'}>
      {isOpen && SelectedStoreReducer?.inView && (
        <Source id="circle-data" type="geojson" data={circleData}>
          <Layer {...circleLayer} />
        </Source>
      )}
      {SelectedStoreReducer?.inView && (
        <Source id="line-data" type="geojson" data={lineData}>
          <Layer {...lineLayer} />
        </Source>
      )}

      {circleDataMap && home && (
        <Source id="circle-data-map" type="geojson" data={circleDataMap}>
          <Layer {...circleLayerMap} />
          <Layer {...circleFillLayer} />
        </Source>
      )}

      {point?.lat && point.lng && showPointMarker && (
        <Marker longitude={point.lng} latitude={point.lat} anchor="bottom">
          <Pin />
        </Marker>
      )}
      {addressesData &&
        !rounded &&
        viewState.zoom > 12 &&
        token &&
        addressesData?.data
          ?.filter((favAdd: any) => favAdd.favourite)
          .map((addr: any) => {
            return (
              <Marker
                key={addr.id}
                longitude={addr.longitude}
                latitude={addr.latitude}
                anchor="bottom"
                onClick={() => {
                  setPopupInfo(null);
                  SetIsOpen(true);
                  setSelAdd(addr);
                  dispatchRedux(setFavAddress(addr));
                  dispatchRedux(remove_current_store());

                  setStorePopUp(false);
                  if (!home && !rounded) {
                    setTimeout(() => {
                      onGetPoint({
                        lngLat: { lng: addr.longitude, lat: addr.latitude },
                      });
                    }, 0);
                  }
                }}>
                <div
                  style={{
                    marginBottom:
                      selAdd?.latitude === addr.latitude ? '-14px' : '0',
                  }}
                  className="favorite_adresses">
                  <img
                    style={{
                      marginBottom:
                        selAdd?.latitude === addr.latitude ? '-30px' : '0',
                      zIndex: '999999',
                    }}
                    src={`${process.env.PUBLIC_URL}/images/home/favAddress.svg`}
                    alt="favorite address"
                  />
                  {selAdd?.latitude === addr.latitude && (
                    <img
                      className="grow-animation"
                      src={`${process.env.PUBLIC_URL}/images/home/favAddressSelected.svg`}
                      alt="favAddressselected"
                    />
                  )}
                </div>
              </Marker>
            );
          })}

      {!rounded && viewState.zoom > 16 ? (
        <Marker
          longitude={position.lng}
          latitude={position.lat}
          offset={[10, 10]}
          pitchAlignment="viewport"
          anchor="center">
          <div ref={targetRef}>
            <RadialMenu
              callbackNavigation={navigation}
              middleComp={
                <div id="RADIAL_HOME_MENU">
                  <img
                    style={{
                      height: '100px',
                    }}
                    src={`${process.env.PUBLIC_URL}/images/home/track.svg`}
                  />
                </div>
              }></RadialMenu>
          </div>
        </Marker>
      ) : null}

      {customPoints.map((p: any, index: number) => (
        <Marker
          key={`point-marker-${index}`}
          longitude={p.point.lng}
          latitude={p.point.lat}
          anchor="bottom">
          {stepByStep ? (
            <LocationPin
              size={30}
              icon={
                index == 0
                  ? './images/icons/pin/navigating/placeholder.png'
                  : './images/icons/pin/navigating/flag.png'
              }
            />
          ) : (
            <Pin />
          )}
        </Marker>
      ))}
      {pathData?.geometry && (
        <Source type="geojson" data={{ ...pathData }}>
          <Layer {...mapLayer} />
        </Source>
      )}
      {showDefaultMarkers &&
        (!stepByStep || navToStore) &&
        defaulMarkers
          .filter((m: any) => {
            const isNavToStore = navToStore
              ? navToStore.latitude == m.latitude &&
                navToStore.longitude == m.longitude
              : true;

            // if (!useMapForNavigation?.useMapForNavigation) {
            //   // Define the center of the circle
            //   let lat = position.lat;
            //   let lng = position.lng;

            //   if (SelectedStoreReducer.inView) {
            //     lat = SelectedStoreReducer.inView.latitude;
            //     lng = SelectedStoreReducer.inView.longitude;
            //   }

            //   const center = turf.point([lng, lat]);
            //   const radius = 0.5; // 500 meters in kilometers
            //   const options: any = { steps: 64, units: 'kilometers' };

            //   // Create the circular polygon
            //   const circle = turf.circle(center, radius, options);

            //   // Create a point for the current marker
            //   const markerPoint = turf.point([m.longitude, m.latitude]);

            //   // Check if the marker is inside the circle
            //   const isInsideCircle = turf.booleanPointInPolygon(
            //     markerPoint,
            //     circle,
            //   );

            //   // Return true if the marker is within the circle and navToStore conditions are met
            //   return isNavToStore && isInsideCircle;
            // }
            return isNavToStore;
          })
          .map((m: any, index: number) => (
            <Marker
              key={`marker-${index}`}
              longitude={m.longitude}
              latitude={m.latitude}
              anchor="bottom"
              onClick={e => {
                e.originalEvent.stopPropagation();
                setPopupInfo({
                  type: 'info',
                  marker: m,
                });
                setSelAdd(null);
                setStorePopUp(true);
                SetIsOpen(true);
                dispatchRedux(setFavAddress(null));
                dispatchRedux(
                  setfilterlists([
                    {
                      type: 'info',
                      marker: m,
                    },
                  ]),
                );
              }}>
              <DefaultMarkerPin
                size={zoomLevel <= 14 ? 40 : 25}
                icon={getIcon(m)}
                type={
                  (zoomLevel <= 14 ? 'rounded/' : 'normal/') +
                  (m.isOpened && !m.isClosed ? '0' : '1')
                }
                dark={m.category == 'logistica'}
                mX={
                  m.subcategory > StoreSubcategoryEnum.Deposit
                    ? 3.8
                    : m.subcategory == StoreSubcategoryEnum.Deposit
                    ? 0.5
                    : 1
                }
                mY={
                  m.subcategory > StoreSubcategoryEnum.Deposit
                    ? 3.8
                    : m.subcategory == StoreSubcategoryEnum.Deposit
                    ? 0.2
                    : 1
                }
                scale={
                  m.subcategory == StoreSubcategoryEnum.Deposit ? 0.75 : 0.5
                }
              />
            </Marker>
          ))}
    </ReactMap>
  );
  const [nextRelatedStore, setNext] = useState(0);

  function isMarkerVisible(marker: any, map: any) {
    const markerLngLat = new mapboxgl.LngLat(marker.lng, marker.lat);

    const bounds = map?.getBounds();

    return bounds?.contains(markerLngLat);
  }

  const [relatedStores, setRelated] = useState<any>();
  const divRef = useRef(null);
  const headerRef = useRef(null);

  useEffect(() => {
    if (popupInfo?.marker) {
      setNext(0);
      const related = defaulMarkers.filter(
        item =>
          item.subcategory === popupInfo?.marker.subcategory &&
          item.id !== popupInfo?.marker.id,
      );
      console.log('related', related);

      related.unshift(popupInfo?.marker);
      setRelated(related);
      dispatchRedux(setRelatedStores(related));
    }
  }, [popupInfo?.marker?.subcategory]);

  useEffect(() => {
    if (popupInfo?.marker?.id)
      fetch(`${process.env.REACT_APP_API}/food/store/${popupInfo?.marker?.id}`)
        .then(res => res.json())
        .then(async res => {
          dispatchRedux(setInView(res.data));
        });
  }, [popupInfo?.marker]);

  useEffect(() => {
    if (!SelectedStoreReducer.Store) {
      setPopupInfo(null);
    } else if (SelectedStoreReducer?.Store?.id) {
      fetch(
        `${process.env.REACT_APP_API}/food/store/${SelectedStoreReducer.Store.id}`,
      )
        .then(res => res.json())
        .then(async res => {
          dispatchRedux(setInView(res.data));
          mapRef?.current?.flyTo({
            center: [res.data.longitude, res.data.latitude],
            zoom: 18.5,
            essential: true,
            duration: 2000,
          });
        });
    }
  }, [SelectedStoreReducer?.Store?.id]);

  useEffect(() => {
    if (!SelectedStoreReducer.inView) {
      setPopupInfo(null);
    }
  }, [SelectedStoreReducer.inView]);

  useEffect(() => {
    if (
      SelectedStoreReducer.inView?.longitude &&
      SelectedStoreReducer.inView?.latitude
    ) {
      mapRef?.current?.flyTo({
        center: [
          SelectedStoreReducer.inView?.longitude,
          SelectedStoreReducer.inView?.latitude,
        ],
        zoom: 18.5,
        essential: true,
        duration: 2000,
      });
    }
  }, [SelectedStoreReducer.inView]);

  return !home ? (
    <div
      style={rounded ? { height: '100px', width: '100%' } : {}}
      className={`mapaddressframe ${
        readonly ? 'mapaddressframe--readonly' : ''
      }`}>
      {mapElement}

      {!rounded && (
        <div
          style={{
            backgroundColor: 'white',
            position: 'fixed',
            bottom: '0',
          }}
          className={`mapaddressframe__button flex flex-col p-4 gap-8 justify-center items-center   ${
            showButton && showPointMarker
              ? 'mapaddressframe__button--visible'
              : ''
          }`}>
          <Button type="primary" onClick={() => getCoords()}>
            {t('mapFrame.button')}
          </Button>
          <div className="mapaddressframe__button__info ">
            {t('mapFrame.buttonInfo')}
          </div>
        </div>
      )}
      <AddressModal
        title={t('mapFrame.addressTitle')}
        visible={modalInfoVisible}
        address={address}
        onCancel={() => {
          setAddress(undefined);
          setModalInfoVisible(false);
        }}
        search={true}
        onSubmit={handleModalInfoVisibleOk}
      />
    </div>
  ) : (
    <>
      <div
        style={{
          width: '100%',
          height: '100vh',
          position: 'relative',
          zIndex: 99999,
        }}>
        {
          <div
            onClick={() => {
              mapRef?.current?.flyTo({
                center: [position.lng, position.lat],
                zoom: 18.5,
                essential: true,
                duration: 2000,
              });
            }}
            style={{
              display: isMarkerVisible(position, mapRef?.current)
                ? viewState.zoom < 16
                  ? 'inline-block'
                  : 'none'
                : 'inline-block',
              position: 'absolute',
              bottom: 110,
              fontFamily: 'Zing Rust,Gothic, sans-serif',
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 1,
              backgroundColor: 'grey',
              padding: '5px 10px 5px 10px',
              borderRadius: '16px',
              color: 'white',
              cursor: 'pointer',
            }}>
            Fly to me
          </div>
        }
        {mapElement}
      </div>
    </>
  );
};
