import React, { useState, useEffect } from 'react';

const pinStyle = {
  cursor: 'pointer',
  fill: '#d00',
  stroke: 'none',
};

function DefaultMarkerPin({
  size = 20,
  icon = './images/icons/food/pizza.png',
  type = '0',
  dark = false,
  mX = 1,
  mY = 1,
  scale = 0.5,
}) {
  const [img, setImg] = useState<string>('');
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsVisible(entry.isIntersecting),
      { threshold: 0.1 },
    );
    const element = document.getElementById(`marker-${type}`);
    if (element) observer.observe(element);
    return () => observer.disconnect();
  }, [type]);

  useEffect(() => {
    if (isVisible) {
      mergeImages(
        [
          { src: `./images/icons/pin/${type}.png` },
          {
            src: icon,
            scale: scale,
            x: 140 * mX,
            y: 60 * mY,
          },
        ],
        null,
        type.includes('rounded/1') ? true : false,
      )
        .then((b64: any) => setImg(b64))
        .catch((err: any) => {
          console.log('err', err);
        });
    }
  }, [isVisible, type, icon, size]);

  return (
    <img
      id={`marker-${type}`}
      src={img ? img : `./images/icons/pin/${type}.png`}
      height={size}
      style={{
        ...pinStyle,
        width: '40px',
        height: '45px',
        padding: 0,
      }}
    />
  );
}

export default React.memo(DefaultMarkerPin);

const defaultOptions = {
  format: 'image/png',
  quality: 0.92,
  width: undefined,
  height: undefined,
  Canvas: undefined,
  crossOrigin: undefined,
};

const mergeImages = (
  sources: any[] = [],
  options: any = {},
  sizeBig: boolean,
) =>
  new Promise(resolve => {
    options = Object.assign({}, defaultOptions, options);

    const canvas = options.Canvas
      ? new options.Canvas()
      : window.document.createElement('canvas');
    const Image = options.Image || window.Image;

    const images = sources.map(
      source =>
        new Promise((_resolve, reject) => {
          if (source.constructor.name !== 'Object') {
            source = { src: source };
          }

          const img = new Image();
          img.crossOrigin = options.crossOrigin;
          img.onerror = (e: any) => reject(new Error("Couldn't load image", e));
          img.onload = () => _resolve(Object.assign({}, source, { img }));
          img.src = source.src;
        }),
    );

    const ctx = canvas.getContext('2d');

    resolve(
      Promise.all(images).then((_images: any) => {
        const getSize = (dim: any) =>
          options[dim] ||
          Math.max(..._images.map((image: any) => image.img[dim]));
        canvas.width = getSize('width');
        canvas.height = getSize('height');

        _images.forEach((image: any, index: number) => {
          ctx.globalAlpha = image.opacity ? image.opacity : 1;
          let targetX, targetY;

          if (index === 1) {
            const baseImage = _images[0].img;
            targetX =
              (canvas.width - baseImage.width * (image.scale || 1)) /
              (sizeBig ? 2 : 3.8);
            targetY =
              (canvas.height - baseImage.height * (image.scale || 1)) / 3.8;
          } else {
            targetX = image.x || 0;
            targetY = image.y || 0;
          }
          return ctx.drawImage(
            image.img,
            targetX,
            targetY,
            canvas.width * (image.scale || 1),
            canvas.height * (image.scale || 1),
          );
        });

        if (options.Canvas && options.format === 'image/jpeg') {
          return new Promise((_resolve, reject) => {
            canvas.toDataURL(
              options.format,
              {
                quality: options.quality,
                progressive: false,
              },
              (err: any, jpeg: any) => {
                if (err) {
                  reject(err);
                  return;
                }
                _resolve(jpeg);
              },
            );
          });
        }

        return canvas.toDataURL(options.format, options.quality);
      }),
    );
  });
