import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MenuOutlined, SearchOutlined } from '@ant-design/icons';
import { Input } from '@pankod/refine-antd';
import { useTranslate } from '@pankod/refine-core';
import { RoundIconButton } from '../roundIconButton';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import { BigSwitcher } from '../bigSwitcher';
import { Address, Coords } from '../../interfaces';
import { FindRouteField } from '../findRouteField';
import Joyride, { CallBackProps, STATUS } from 'react-joyride';

import './styles.css';
import { FilterItem } from '../filterList/filterItem';
import icons from '../../constants/icons';

export interface INavBar {
  white?: boolean;
  dark?: boolean;
  search?: boolean;
  onSearch?: (value: string) => void;
  routeFinder?: boolean;
  onRouteFind?: (start: Coords, end: Coords) => void;
  avatar?: boolean;
  onAvatar?: () => void;
  location?: boolean;
  removeNavigationMode?: boolean;
  onRemoveNavigationMode?: any;
  ShowStores?: () => void;
  onFilters?: () => void;
  OnuseforNavigation?: () => void;
  back?: boolean;
  onBack?: () => void;
  title?: string;
  useforNavigation?: boolean;
  mapSwitchOptions?: {
    onMapSwitch: (res: boolean) => void;
    leftLabel: string;
    rightLabel: string;
    value: boolean;
  };
  callSupprt?: boolean;
  filters?: boolean;
  useSearch?: any;
  finishedTour?: boolean;
  searching?: boolean;
  removeSearching?: () => void;
  showPin?: boolean;
}

export const NavBar: React.FC<INavBar> = ({
  white, // Background bianco della barra di ricerca
  dark, // Background scuro della barra di ricerca
  search, // Flag per mostrare/nascondere la barra di ricerca
  onSearch, // Funzione di callback della barra di ricerca
  routeFinder, // Flag per mostrare/nascondere la barra di creazione di un percorso
  onRouteFind, // Funzione di callback della barra di creazione di un percorso
  avatar, // Flag per mostrare/nascondere l'icona dell'avatar
  onAvatar, // Funzione di callback per entrare nel profilo dell'utente
  location, // Flag per mostrare/nascondere l'icona della centratura della mappa
  removeNavigationMode, //to remove navigatiion mode and to see the stores
  onRemoveNavigationMode, //remove navigation icon and put remove button
  ShowStores, // Funzione di callback per cetrare la mappa
  back, // Flag per mostrare/nascondere l'icona per tornare alla pagina precedente
  onBack, // Funzione di callback per tornare alla pagina precedente
  title, // Titolo della pagina
  mapSwitchOptions, // Flag per mostrare lo switcher mappa/lista
  useforNavigation, //use map for navigation
  OnuseforNavigation,
  callSupprt,
  filters,
  onFilters,
  useSearch,
  finishedTour,
  searching,
  removeSearching,
  showPin,
}) => {
  const isMobile = useCheckMobileScreen();
  const t = useTranslate();

  // Campo di ricerca
  const inputVal = useRef('');
  // Callback della funzione di ricerca
  const searchCallback = useCallback(val => {
    if (onSearch) {
      onSearch(val);
    }
  }, []);
  const [locationUrl, setlocationUrl] = useState('');
  const [locationUrl1, setlocationUrl1] = useState('');

  useEffect(() => {
    if (process.env.REACT_APP_SERVER === 'http://localhost:3020/api') {
      setlocationUrl('http://localhost:3000/webapp/home');
      setlocationUrl1('http://localhost:3000/webapp/');
    } else if (
      process.env.REACT_APP_SERVER === 'http://vps-7777ca76.vps.ovh.net:3020'
    ) {
      setlocationUrl('https://development.cocaiexpress.com/webapp/home');
      setlocationUrl1('https://development.cocaiexpress.com/webapp/');
    } else if (
      process.env.REACT_APP_SERVER === 'https://www.cocaiexpress.com/api'
    ) {
      setlocationUrl('https://www.cocaiexpress.com/webapp/home');
      setlocationUrl1(' https://www.cocaiexpress.com/webapp/');
    }
  }, [removeNavigationMode]);

  const makeBottomSheetUp = useCallback(() => {
    if (useSearch) {
      useSearch();
    }
  }, []);

  return (
    <div
      className={`navbar ${showPin ? 'navbar-pin' : ''} ${
        white ? 'navbar--white' : ''
      } ${dark ? 'navbar--dark' : ''}`}>
      <div
        className={`navbar__left  ${!isMobile ? 'navbar__left--desktop' : ''}`}>
        {back && onBack && <RoundIconButton type="back" onClick={onBack} />}
        {filters && (
          <div style={{ backgroundColor: 'white' }} className="rounded-div">
            <img
              onClick={onFilters}
              style={{
                backgroundColor: 'transparent',
                // borderRadius: '20px',
                height: '30px',
              }}
              src={`${process.env.PUBLIC_URL}/images/newNavImage.svg`}
            />
          </div>
        )}
        {!searching && ShowStores && (
          <div
            style={{ cursor: 'pointer' }}
            className="rounded-div"
            id="PIN_ITEMS"
            onClick={ShowStores}>
            <svg
              width="20"
              height="22"
              viewBox="0 0 20 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.2207 4.67676C6.2207 2.58496 7.9082 0.871094 10.0088 0.871094C12.1094 0.871094 13.7881 2.58496 13.7881 4.67676C13.7881 6.38184 12.6631 7.84082 11.0986 8.29785V13.791C11.0986 15.8301 10.4395 17.4121 10.0088 17.4121C9.57812 17.4121 8.90137 15.8301 8.90137 13.791V8.29785C7.3457 7.82324 6.2207 6.38184 6.2207 4.67676ZM8.93652 4.89648C9.65723 4.89648 10.2373 4.29004 10.2373 3.5957C10.2373 2.89258 9.65723 2.30371 8.93652 2.30371C8.25098 2.30371 7.64453 2.89258 7.64453 3.5957C7.64453 4.29004 8.25098 4.89648 8.93652 4.89648ZM9.99121 21.2441C3.95312 21.2441 0.165039 19.2842 0.165039 16.7793C0.165039 14.3359 3.70703 12.6572 7.30176 12.3936V14.3887C4.94629 14.5732 2.5293 15.4609 2.5293 16.6562C2.5293 18.1768 5.64062 19.1875 9.99121 19.1875C14.3418 19.1875 17.4619 18.168 17.4619 16.6562C17.4619 15.4609 15.0449 14.5732 12.6895 14.3887V12.3936C16.2842 12.6572 19.8262 14.3359 19.8262 16.7793C19.8262 19.2842 16.0293 21.2441 9.99121 21.2441Z"
                fill="#7C7C7C"
              />
            </svg>
          </div>
        )}
      </div>
      <div
        style={{ marginRight: '40px' }}
        className={`navbar__center ${title ? 'navbar__center--title' : ''}`}>
        {(search || routeFinder) && (
          <span>
            {search && (
              <Input
                onFocus={() => {
                  console.log('focused');

                  makeBottomSheetUp();
                }}
                className={`${
                  showPin ? ' navbar-show-pin' : ''
                } navbar__center__search `}
                size="large"
                placeholder={t('navBar.find')}
                prefix={
                  <span
                    className="navbar__center__searchicon"
                    onClick={() => searchCallback(inputVal.current)}
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL}/images/icons/search.svg)`,
                    }}>
                    &nbsp;
                  </span>
                }
                value={inputVal.current}
                onChange={(e: any) => {
                  inputVal.current = e.target.value;
                  searchCallback(e.target.value);
                }}
                onPressEnter={() => searchCallback(inputVal.current)}
              />

              // <Input

              //   className="navbar__center__search"
              //   size="large"
              //   placeholder={t('navBar.find')}
              //   prefix={
              //     <span
              //       className="navbar__center__searchicon"
              //       onClick={() => searchCallback(inputVal.current)}
              //       style={{
              //         backgroundImage: `url(${process.env.PUBLIC_URL}/images/icons/search.svg)`,
              //       }}>
              //       &nbsp;
              //     </span>
              //   }
              //   onChange={(e: any) => {
              //     inputVal.current = e.target.value;
              //     searchCallback(e.target.value);
              //   }}
              //   onPressEnter={() => searchCallback(inputVal.current)}
              // />
            )}
            {/* {routeFinder && (
              <span className="navbar__center__route">
                <FindRouteField placeholder={t('navBar.route')} />
              </span>
            )} */}
          </span>
        )}

        {title && (
          <p style={{ marginLeft: '-50px' }} className="navbar__center__title">
            {title}
          </p>
        )}
        {mapSwitchOptions && (
          <BigSwitcher
            leftLabel={mapSwitchOptions.leftLabel}
            rightLabel={mapSwitchOptions.rightLabel}
            onChange={mapSwitchOptions.onMapSwitch}
            value={mapSwitchOptions.value}
          />
        )}
      </div>

      {callSupprt && (
        <div className={'ChiamaCocaiSupport'}>
          <a
            target="_blank"
            rel="nofollow noreferrer noopener"
            href="tel:+390418520108"
            className="call-link">
            <div className="flex ">
              <img
                src={icons.callCocai}
                alt={'cell'}
                className="filter__icon"
              />
              <p
                style={{
                  fontFamily: 'Zing Rust,Gothic, sans-serif',
                  fontSize: '17px',
                }}
                className="filter__title">
                {t('chiamaCocai.title')}
              </p>
            </div>
          </a>
        </div>
      )}
      <div
        className={`navbar__right  ${
          !isMobile ? 'navbar__right--desktop' : ''
        }`}>
        {avatar && onAvatar && !searching ? (
          <div className="profile_picture_avator">
            <RoundIconButton type="avatar" onClick={onAvatar} />
          </div>
        ) : searching ? (
          <img
            onClick={() => {
              if (removeSearching) removeSearching();
              inputVal.current = '';
              searchCallback('');
            }}
            style={{
              backgroundColor: 'white',
              // borderRadius: '20px',
              borderRadius: '50%',
              height: '100%',
              cursor: 'pointer',
            }}
            src={`${process.env.PUBLIC_URL}/images/cancelNav-2.svg`}
          />
        ) : null}
      </div>
    </div>
  );
};
